import React from "react";
import { Row, Col } from "react-bootstrap";
import contactImage from "../assets/contact-image.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import shopeeLogoImage from "../assets/shopee-logo-contact.jpg";
import lazadaLogoImage from "../assets/lazada-logo-contact.jpg";

const ContactUs = () => {
  return (
    <div className="about-us">
      <Row className="about-us-wrapper">
        <div className="about-us-top">
          <div className="about-us-title">联系</div>
          <div className="about-us-line"></div>
        </div>
        <p className="about-us-explain">
        " 欢迎随时通过以下所提供的联系信息与我们联系，或在社交媒体平台上与我们互动——我们很愿意听到您的声音！"
        </p>

        <Col md="12" className="about-us-center">
          <div className="design-box"></div>
          <div className="white-div"> <img
            className="about-image"
            src={contactImage}
            alt="contact-img"
          ></img></div>

        </Col>
        <Col md="12" className="about-us-bottom">
          <div className="contact-us-social">
            <p className="sub-title">保持联系</p>
            <a
              href="https://www.facebook.com/info.infinitinutrition"
              target="_blank"
              rel="noreferrer"
              className="social-link"
            >
              {" "}
              <FacebookIcon className="social-icon" />
            </a>
            <a
              href="https://www.instagram.com/infiniti.nutrition"
              target="_blank"
              rel="noreferrer"
              className="social-link"
            >
              {" "}
              <InstagramIcon
                className="social-icon"
                style={{ margin: "0px 1px" }}
              />
            </a>
            <a
              href="https://wa.link/f0y0ye"
              target="_blank"
              rel="noreferrer"
              className="social-link"
            >
              {" "}
              <WhatsAppIcon className="social-icon" />
            </a>
            <a
              href="https://shopee.com.my/infinitinutrition"
              target="_blank"
              rel="noreferrer"
              className="social-link"
            >
              {" "}
              <img src={shopeeLogoImage} className="social-shopee-img" alt='shopee'></img>
            </a>
            <a
              href="https://www.lazada.com.my/shop/infiniti-nutrition"
              target="_blank"
              rel="noreferrer"
              className="social-link"
            >
              {" "}
              <img src={lazadaLogoImage} className="social-lazada-img" alt='lazada'></img>
            </a>
          </div>
          <div className="contact-us-company-info">
            <p className="sub-title">公司信息</p>
            <div className="icon-wrapper"><LocationOnIcon className="company-info-icon" style={{ marginTop: '0' }} /></div>
            <p className='company-info-text'>
              Infiniti Nutrition Sdn. Bhd.<br />202301030886 (1524809-H)
            </p>
            <p className='company-info-text' style={{ marginTop: '10px' }}>
              No 15, Lorong Perkasa Jaya, Taman Perkasa Jaya, 13000 Butterworth,
              Pulau Pinang, Malaysia.
            </p>
            <div className="icon-wrapper"><MailOutlineIcon className="company-info-icon" /></div>
            <a className='company-info-text-email' href="mailto:info.infinitinutrition@gmail.com">info.infinitinutrition@gmail.com</a>
            <div className="icon-wrapper"><SmartphoneIcon className="company-info-icon" /></div>
            <a className='company-info-text-email' href="tel:60107873633">+6010-7873633</a>
            <p></p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ContactUs;
