import biofiber1 from '../assets/Biofiber/1.png'
import biofiber2 from '../assets/Biofiber/2.png'
import biofiber3 from '../assets/Biofiber/3.png'
import biofiber4 from '../assets/Biofiber/4.png'
import biofiber5 from '../assets/Biofiber/5.png'
import biofiber6 from '../assets/Biofiber/6.png'
import biofiber7 from '../assets/Biofiber/7.png'

import bioblock1 from '../assets/Bioblock/1.png'
import bioblock2 from '../assets/Bioblock/2.png'
import bioblock3 from '../assets/Bioblock/3.png'
import bioblock4 from '../assets/Bioblock/4.png'
import bioblock5 from '../assets/Bioblock/5.png'
import bioblock6 from '../assets/Bioblock/6.png'
import bioblock7 from '../assets/Bioblock/7.png'

import biobeauty1 from '../assets/Biobeauty/1.png'
import biobeauty2 from '../assets/Biobeauty/2.png'
import biobeauty3 from '../assets/Biobeauty/3.png'
import biobeauty4 from '../assets/Biobeauty/4.png'
import biobeauty5 from '../assets/Biobeauty/5.png'
import biobeauty6 from '../assets/Biobeauty/6.png'
import biobeauty7 from '../assets/Biobeauty/7.png'

import biowhite1 from '../assets/Biowhite/1.png'
import biowhite2 from '../assets/Biowhite/2.png'
import biowhite3 from '../assets/Biowhite/3.png'
import biowhite4 from '../assets/Biowhite/4.png'
import biowhite5 from '../assets/Biowhite/5.png'
import biowhite6 from '../assets/Biowhite/6.png'
import biowhite7 from '../assets/Biowhite/7.png'

import biogent1 from '../assets/Biogent/1.png'
import biogent2 from '../assets/Biogent/2.png'
import biogent3 from '../assets/Biogent/3.png'
import biogent4 from '../assets/Biogent/4.png'
import biogent5 from '../assets/Biogent/5.png'
import biogent6 from '../assets/Biogent/6.png'
import biogent7 from '../assets/Biogent/7.png'

import biolady1 from '../assets/Biolady/1.png'
import biolady2 from '../assets/Biolady/2.png'
import biolady3 from '../assets/Biolady/3.png'
import biolady4 from '../assets/Biolady/4.png'
import biolady5 from '../assets/Biolady/5.png'
import biolady6 from '../assets/Biolady/6.png'
import biolady7 from '../assets/Biolady/7.png'

import biovision1 from '../assets/Biovision/1.png'
import biovision2 from '../assets/Biovision/2.png'
import biovision3 from '../assets/Biovision/3.png'
import biovision4 from '../assets/Biovision/4.png'
import biovision5 from '../assets/Biovision/5.png'
import biovision6 from '../assets/Biovision/6.png'
import biovision7 from '../assets/Biovision/7.png'

import biobrain1 from '../assets/Biobrain/1.png'
import biobrain2 from '../assets/Biobrain/2.png'
import biobrain3 from '../assets/Biobrain/3.png'
import biobrain4 from '../assets/Biobrain/4.png'
import biobrain5 from '../assets/Biobrain/5.png'
import biobrain6 from '../assets/Biobrain/6.png'
import biobrain7 from '../assets/Biobrain/7.png'

import bioliver1 from '../assets/Bioliver/1.png'
import bioliver2 from '../assets/Bioliver/2.png'
import bioliver3 from '../assets/Bioliver/3.png'
import bioliver4 from '../assets/Bioliver/4.png'
import bioliver5 from '../assets/Bioliver/5.png'
import bioliver6 from '../assets/Bioliver/6.png'
import bioliver7 from '../assets/Bioliver/7.png'

import biorelax1 from '../assets/Biorelax/1.png'
import biorelax2 from '../assets/Biorelax/2.png'
import biorelax3 from '../assets/Biorelax/3.png'
import biorelax4 from '../assets/Biorelax/4.png'
import biorelax5 from '../assets/Biorelax/5.png'
import biorelax6 from '../assets/Biorelax/6.png'
import biorelax7 from '../assets/Biorelax/7.png'


export const biofiberImage =[biofiber1, biofiber2, biofiber3, biofiber4, biofiber5, biofiber6, biofiber7]
export const bioblockImage =[bioblock1, bioblock2, bioblock3, bioblock4, bioblock5, bioblock6, bioblock7]
export const biobeautyImage =[biobeauty1, biobeauty2, biobeauty3, biobeauty4, biobeauty5, biobeauty6, biobeauty7]
export const biowhiteImage =[biowhite1, biowhite2, biowhite3, biowhite4, biowhite5, biowhite6, biowhite7]
export const biogentImage =[biogent1, biogent2, biogent3, biogent4, biogent5, biogent6, biogent7]
export const bioladyImage =[biolady1, biolady2, biolady3, biolady4, biolady5, biolady6, biolady7]
export const biovisionImage =[biovision1, biovision2, biovision3, biovision4, biovision5, biovision6, biovision7]
export const biobrainImage =[biobrain1, biobrain2, biobrain3, biobrain4, biobrain5, biobrain6, biobrain7]
export const bioliverImage =[bioliver1, bioliver2, bioliver3, bioliver4, bioliver5, bioliver6, bioliver7]
export const biorelaxImage =[biorelax1, biorelax2, biorelax3, biorelax4, biorelax5, biorelax6, biorelax7]
