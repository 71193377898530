import React from "react";
import { Row, Col } from "react-bootstrap";
import aboutImage from "../assets/about-image.png";

const AboutUs = () => {

  return (
    <div className="about-us">
      <Row className="about-us-wrapper">
        <div className="about-us-top">
          <div className="about-us-title">简介</div>
          <div className="about-us-line"></div>
        </div>
        <p className="about-us-explain">
          " 提升您的健康之旅，与 INFINITI NUTRITION 共创卓越。"
        </p>

        <Col md="12" className="about-us-center ">
          <div className="design-box"></div>
          <div className="white-div"> <img
            className="about-image"
            src={aboutImage}
            alt="about-img"
          ></img></div>

        </Col>
        <Col md="12" className="about-us-bottom">

          {/* in order to use the css style */}
          <div className="contact-us-company-info">
            <p className="company-info-text">
              <p style={{fontSize:'14px', fontWeight:'400'}}><h5>概述：</h5> Infiniti Nutrition 是一家充满活力和创新的公司，致力于提供高质量的保健食品和健康产品，以支持整体健康和活力。我们秉持卓越的承诺和推广健康生活方式的热情，力求提供全面的产品系列，以满足客户的多样化需求。</p>
              <p style={{fontSize:'14px', fontWeight:'400'}}><h5>使命声明：</h5> 在 Infiniti Nutrition，我们的使命是通过卓越的营养让个人掌控自己的健康，发挥他们的全部潜力。我们相信每个人都应享有优质健康食品，提升活力，促进长寿，支持最佳健康。我们专注于创新、诚信和客户满意，旨在成为更好健康之旅中的值得信赖的伙伴。</p>
              <p style={{fontSize:'14px', fontWeight:'400'}}><h5>质量保证：</h5> 在 Infiniti Nutrition，我们优先考虑质量和安全。我们的产品使用最先进的设备制造，使用来自可信供应商的优质原料。我们遵循严格的质量控制标准和严格的测试协议，以确保每个产品都符合最高的安全和效力标准。此外，我们的配方基于最新的科学研究，不含人工添加剂、填充剂和不必要的成分。</p>
              <p style={{fontSize:'14px', fontWeight:'400'}}><h5>客户满意度：</h5> 我们致力于提供卓越的客户服务，并与我们的客户建立长期关系。我们知识渊博、敬业的专业团队随时为您提供个性化支持，回答问题并提供专家建议。我们重视客户的反馈，并不断努力改进和创新产品，以满足客户不断变化的需求。</p>
              <h5>加入 Infiniti Nutrition 社区</h5>
              <p style={{fontSize:'14px', fontWeight:'400'}}>体验 Infiniti Nutrition 的卓越营养无限可能。加入我们的健康意识社区，通过最佳的营养和健康生活，致力于过上最好生活。无论您是想改善肠道健康、提升皮肤状态，还是支持整体健康和活力，Infiniti Nutrition 都拥有帮助您实现目标的产品和资源。</p>
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AboutUs;
