import React from "react";
import { Row, Col } from "react-bootstrap";
import aboutImage from "../assets/about-image.png";

const AboutUs = () => {

  return (
    <div className="about-us">
      <Row className="about-us-wrapper">
        <div className="about-us-top">
          <div className="about-us-title">About Us</div>
          <div className="about-us-line"></div>
        </div>
        <p className="about-us-explain">
          " Elevate Your Wellness Journey with INFINITI NUTRITION: Where Health Meets Excellence. "
        </p>

        <Col md="12" className="about-us-center ">
          <div className="design-box"></div>
          <div className="white-div"> <img
            className="about-image"
            src={aboutImage}
            alt="about-img"
          ></img></div>

        </Col>
        <Col md="12" className="about-us-bottom">

          {/* in order to use the css style */}
          <div className="contact-us-company-info">
            <p className="company-info-text">
              <p><h5>Overview:</h5> Infiniti Nutrition is a dynamic and innovative company dedicated to providing high-quality food supplements and health products to support overall well-being and vitality. With a commitment to excellence and a passion for promoting healthy lifestyles, we strive to offer a comprehensive range of products that cater to the diverse needs of our customers.</p>
              <p><h5>Mission Statement:</h5> At Infiniti Nutrition, our mission is to empower individuals to take control of their health and unlock their full potential through superior nutrition. We believe that everyone deserves access to premium-quality supplements that enhance vitality, promote longevity, and support optimal wellness. With a focus on innovation, integrity, and customer satisfaction, we aim to be a trusted partner on the journey to better health.</p>
              <p><h5>Quality Assurance:</h5> At Infiniti Nutrition, we prioritize quality and safety above all else. Our products are manufactured in state-of-the-art facilities using premium ingredients sourced from trusted suppliers. We adhere to strict quality control standards and rigorous testing protocols to ensure that every product meets the highest safety and efficacy standards. Additionally, our formulations are based on the latest scientific research and are free from artificial additives, fillers, and unnecessary ingredients.</p>
              <p><h5>Customer Satisfaction:</h5> We are committed to delivering exceptional customer service and fostering long-lasting relationships with our customers. Our team of knowledgeable and dedicated professionals is always available to provide personalized support, answer questions, and offer expert advice. We value feedback from our customers and continuously strive to improve and innovate our products to meet their evolving needs.</p>
              <h5>Join the Infiniti Nutrition Community</h5>
              <p>Experience the endless possibilities of superior nutrition with Infiniti Nutrition. Join our community of health-conscious individuals dedicated to living their best lives through optimal nutrition and wellness. Whether you're aiming to improve your gut health, enhance your skin, or support your overall health and vitality, Infiniti Nutrition has the products and resources to help you achieve your goals.</p>

            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AboutUs;
