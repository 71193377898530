import React from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import QNELogo from '../assets/infiniti-name-logo.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Button} from "react-bootstrap";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

    const handleClick = () => {
      const pathAfterEn = location.pathname.replace('/cn/', '');

      switch (pathAfterEn) {
        case 'contactus':
          navigate('/en/contactus');
          break;
        case 'aboutus':
          navigate('/en/aboutus');
          break;
        default:
          navigate('/en/');
          break;
      }
  }
  return (
    <Navbar expand="lg" className='navbar'>
    <Container>
      <Navbar.Brand href="./"><img src={QNELogo} alt='logo' className="qne-logo"></img></Navbar.Brand>
      <Button className="language-btn language-btn-center" onClick={handleClick}>CN</Button>
      <Navbar.Toggle aria-controls="basic-navbar-nav"/>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link className='navlink-text' href="./">主页</Nav.Link>
          <Nav.Link className='navlink-text' href="./aboutus">简介</Nav.Link>
          <Nav.Link className='navlink-text' href="./contactus">联系</Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <Button className="language-btn language-btn-right" onClick={handleClick}>CN</Button>
    </Container>
  </Navbar>
  )
}

export default Header
