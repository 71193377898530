import React, { useState, useEffect, useRef } from "react";
import { CarouselProvider, Slider, Slide, Dot, Image, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { biofiberImage, bioblockImage, biobeautyImage, biowhiteImage, biogentImage, bioladyImage, biovisionImage, biobrainImage, bioliverImage, biorelaxImage } from "./image";
import { data } from "./data";
import { Link } from "react-router-dom";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import CancelIcon from '@mui/icons-material/Cancel';
import ArtTrackIcon from "@mui/icons-material/ArtTrack";
import shopeeLogo from "../assets/shopee-logo.jpg";
import lazadaLogo from "../assets/lazada-logo.jpg";
import whatsappLogo from "../assets/whatsapp-logo.jpg";
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const [productImage, setProductImage] = useState(biofiberImage);
  const [product, setProduct] = useState("biofiber");
  const [descriptionLanguage, setDescriptionLanguage] = useState();
  const [descriptionContent, setDescriptionContent] = useState();
  const [render, setRender] = useState(true);
  const [shopeeLink, setShopeeLink] = useState("https://shopee.com.my/product/1227359667/25527075845/");
  const [lazadaLink, setLazadaLink] = useState("https://www.lazada.com.my/products/infiniti-biofiber-comprehensive-fiber-enzymes-detox-for-weight-loss-digestive-health-30-x-1000mg-chewable-tablets-i4086478529-s23149731883.html");

  // ----------------- check the link is EN or CN --------------------------------------------

  const url = window.location.href;

  useEffect(() => {
    if (url.includes("en")) {
      setDescriptionLanguage("EN");
    } else {
      setDescriptionLanguage("CN");
    }  }, [url]);

  // ----------------- change description content --------------------------------------------

  useEffect(() => {
    setDescriptionContent(data[product][descriptionLanguage]);
  }, [product, descriptionLanguage]);

  // ----------------- re-render images when changing product----------------------------------

  useEffect(() => {
    setRender(false);
    setTimeout(() => {
      setRender(true);
    }, 10);
  }, [product]);

  // ----------------- show full Image -------------------------------------------------------

  const [showImage, setShowImage] = useState(false);
  const imageRef = useRef(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // change url with query enable us to click back without change the base url
  const navigate = useNavigate();
  const handleClick = () => {
    setShowImage(true);
    setCurrentImageIndex(imageRef.current.state.currentSlide)
    navigate('?query=enlargeImage');
  }

  // when back button of the browser is clicked
  window.addEventListener('popstate', () => {
    setShowImage(false);
  });

  const handleClose = () => {
    setShowImage(false);
  }

  // fake back button on full screen
  const handleFakeBack = () => {
    const realBack = document.querySelector(".real-back")
    realBack.click()
    // the click does not change the state immediately, 
    // so we need a delay function, so that it runs after the state has been updated.
    setTimeout(() => {
      setCurrentImageIndex(imageRef.current.state.currentSlide);
    }, 0);
  }

  // fake next button on full screen
  const handleFakeNext = () => {
    const realNext = document.querySelector(".real-next")
    realNext.click()
    setTimeout(() => {
      setCurrentImageIndex(imageRef.current.state.currentSlide);
    }, 0);
  }

  // change the image display in full screen
  useEffect(() => {
    const currentImageFull = document.querySelector(".current-image-full");
    if (currentImageFull) {
      currentImageFull.src = productImage[currentImageIndex]
    }
  }, [currentImageIndex, productImage]);

  document.addEventListener('touchend', () => {
    if (imageRef.current) {
      setCurrentImageIndex(imageRef.current.state.currentSlide)
    }
  })

  // ----------------- // -------------------------------------------------------

  const [carouselWidth, setCarouselWidth] = useState();
  const [carouselHeight, setCarouselHeight] = useState();

  useEffect(() => {

    const carouselWidth = document.querySelector('.carousel-wrapper').clientWidth
    setCarouselWidth(carouselWidth);
    const carouselHeight = document.querySelector('.carousel-wrapper').clientHeight
    setCarouselHeight(carouselHeight)

  }, []);

  return (
    <div className="home">
      {/* ---------------------full screen image-------------------------------------- */}
      {showImage && (
        <div class="modal-row">
          <div className="close-wrapper">
            <CancelIcon onClick={handleClose}
              className="cancel-icon"
            />
          </div>

          <div class="modal-content">
            <img
              src={productImage[currentImageIndex]}
              alt="img"
              className="current-image-full"
            />
          </div>
          <div className="fake-back-wrapper" onClick={handleFakeBack}>
            <KeyboardDoubleArrowLeftIcon
              className="fake-back"

            />
          </div>
          <div className="fake-next-wrapper" onClick={handleFakeNext}>
            <KeyboardDoubleArrowRightIcon
              className="fake-next"

            />
          </div>
        </div>
      )}
      {/* --------------------- product btn -------------------------------------- */}

      <div className="product-btn-wrapper">
        <Link
          className="product-btn"
          style={product === "biofiber" ? { color: "#0991099d" } : {}}
          onClick={() => {
            setProduct("biofiber");
            setProductImage(biofiberImage);
            setShopeeLink(
              "https://shopee.com.my/product/1227359667/25527075845/"
            );
            setLazadaLink(
              "https://www.lazada.com.my/products/infiniti-biofiber-comprehensive-fiber-enzymes-detox-for-weight-loss-digestive-health-30-x-1000mg-chewable-tablets-i4086478529-s23149731883.html"
            );
          }}
        >
          <div className="icon-wrapper">
            <ArtTrackIcon />
          </div>
          INFINITI BIOFIBER
        </Link>
        <Link
          className="product-btn"
          style={product === "bioblock" ? { color: "#0991099d" } : {}}
          onClick={() => {
            setProduct("bioblock");
            setProductImage(bioblockImage);
            setShopeeLink(
              "https://shopee.com.my/product/1227359667/24877078520/"
            );
            setLazadaLink(
              "https://www.lazada.com.my/products/infiniti-bioblock-comprehensive-carbohydrate-fat-blocker-with-metabolism-boost-30-x-1000mg-chewable-tablets-i4086482454-s23150043151.html"
            );
          }}
        >
          <div className="icon-wrapper">
            <ArtTrackIcon />
          </div>
          INFINITI BIOBLOCK
        </Link>
        <Link
          className="product-btn"
          style={product === "biobeauty" ? { color: "#0991099d" } : {}}
          onClick={() => {
            setProduct("biobeauty");
            setProductImage(biobeautyImage);
            setShopeeLink(
              "https://shopee.com.my/product/1227359667/24377086970/"
            );
            setLazadaLink(
              "https://www.lazada.com.my/products/infiniti-biobeauty-comprehensive-collagen-boost-with-stem-cell-anti-aging-stimulation-30-x-1000mg-chewable-tablets-i4086498216-s23149764777.html"
            );
          }}
        >
          <div className="icon-wrapper">
            <ArtTrackIcon />
          </div>
          INFINITY BIOBEAUTY
        </Link>
        <Link
          className="product-btn"
          style={product === "biowhite" ? { color: "#0991099d" } : {}}
          onClick={() => {
            setProduct("biowhite");
            setProductImage(biowhiteImage);
            setShopeeLink(
              "https://shopee.com.my/product/1227359667/25377087079/"
            );
            setLazadaLink(
              "https://www.lazada.com.my/products/infiniti-biowhite-comprehensive-whitening-brightening-with-dark-spot-reduction-30-x-1000mg-chewable-tablets-i4086452900-s23149963526.html"
            );
          }}
        >
          <div className="icon-wrapper">
            <ArtTrackIcon />
          </div>
          INFINITI BIOWHITE
        </Link>
        <Link
          className="product-btn"
          style={product === "biogent" ? { color: "#0991099d" } : {}}
          onClick={() => {
            setProduct("biogent");
            setProductImage(biogentImage);
            setShopeeLink(
              "https://shopee.com.my/product/1227359667/25827363854/"
            );
            setLazadaLink(
              "https://www.lazada.com.my/products/infiniti-biogent-comprehensive-mens-health-for-masculine-health-overall-vitality-30-x-1000mg-chewable-tablets-i4086509150-s23150139208.html"
            );
          }}
        >
          <div className="icon-wrapper">
            <ArtTrackIcon />
          </div>
          INFINITI BIOGENT
        </Link>
        <Link
          className="product-btn"
          style={product === "biolady" ? { color: "#0991099d" } : {}}
          onClick={() => {
            setProduct("biolady");
            setProductImage(bioladyImage);
            setShopeeLink(
              "https://shopee.com.my/product/1227359667/24827369073/"
            );
            setLazadaLink(
              "https://www.lazada.com.my/products/infiniti-biolady-comprehensive-womens-health-for-feminine-health-overall-vitality-30-x-1000mg-chewable-tablets-i4086524101-s23150161083.html"
            );
          }}
        >
          <div className="icon-wrapper">
            <ArtTrackIcon />
          </div>
          INFINITI BIOLADY
        </Link>
        <Link
          className="product-btn"
          style={product === "biovision" ? { color: "#0991099d" } : {}}
          onClick={() => {
            setProduct("biovision");
            setProductImage(biovisionImage);
            setShopeeLink(
              "https://shopee.com.my/product/1227359667/24877097541/"
            );
            setLazadaLink(
              "https://www.lazada.com.my/products/infiniti-biovision-comprehensive-eye-health-with-enhanced-vision-and-retinal-health-30-x-1000mg-chewable-tablets-i4086505183-s23150055466.html"
            );
          }}
        >
          <div className="icon-wrapper">
            <ArtTrackIcon />
          </div>
          INFINITI BIOVISION
        </Link>
        <Link
          className="product-btn"
          style={product === "biobrain" ? { color: "#0991099d" } : {}}
          onClick={() => {
            setProduct("biobrain");
            setProductImage(biobrainImage);
            setShopeeLink(
              "https://shopee.com.my/product/1227359667/25627097439/"
            );
            setLazadaLink(
              "https://www.lazada.com.my/products/infiniti-biobrain-comprehensive-brain-health-with-memory-and-cognitive-boost-30-x-1000mg-chewable-tablets-i4086476589-s23150021629.html"
            );
          }}
        >
          <div className="icon-wrapper">
            <ArtTrackIcon />
          </div>
          INFINITI BIOBRAIN
        </Link>
        <Link
          className="product-btn"
          style={product === "bioliver" ? { color: "#0991099d" } : {}}
          onClick={() => {
            setProduct("bioliver");
            setProductImage(bioliverImage);
            setShopeeLink(
              "https://shopee.com.my/product/1227359667/25327097972/  "
            );
            setLazadaLink(
              "https://www.lazada.com.my/products/infiniti-bioliver-comprehensive-liver-health-for-detoxification-and-disease-prevention-30-x-1000mg-chewable-tablets-i4086521114-s23150044456.html"
            );
          }}
        >
          <div className="icon-wrapper">
            <ArtTrackIcon />
          </div>
          INFINITI BIOLIVER
        </Link>
        <Link
          className="product-btn"
          style={product === "biorelax" ? { color: "#0991099d" } : {}}
          onClick={() => {
            setProduct("biorelax");
            setProductImage(biorelaxImage);
            setShopeeLink(
              "https://shopee.com.my/product/1227359667/25627098369/"
            );
            setLazadaLink(
              "https://www.lazada.com.my/products/infiniti-biorelax-comprehensive-stress-relief-and-deep-sleep-support-for-insomnia-30-x-1000mg-chewable-tablets-i4086437926-s23150106272.html"
            );
          }}
        >
          <div className="icon-wrapper">
            <ArtTrackIcon />
          </div>
          INFINITI BIORELAX
        </Link>
      </div>
      {/* --------------------- product carousel -------------------------------------- */}

      <div className="carousel-wrapper">
        {!render && (<div style={{ width: `${carouselWidth}px`, height: `${carouselHeight}px`, backgroundColor: 'white' }}></div>)}
        {render && (
          <CarouselProvider
            naturalSlideWidth={20}
            naturalSlideHeight={20}
            orientation="horizontal"
            hasMasterSpinner="true"
            interval="5000"
            isPlaying="true"
            totalSlides={productImage.length}
          >
            <Slider>
              <div className="slider-top-space"></div>
              {productImage.map((image, index) => (
                <Slide index={index} key={index} ref={imageRef}>
                  <img
                    onClick={handleClick}
                    className="d-block w-100 product-card-image"
                    src={image}
                    alt='product-info-and-review'
                  />

                  <ButtonBack className="real-back">
                    <KeyboardDoubleArrowLeftIcon />
                  </ButtonBack>
                  <ButtonNext className="real-next">
                    <KeyboardDoubleArrowRightIcon />
                  </ButtonNext>
                </Slide>
              ))}
            </Slider>

            <div className="all-dots">
              {productImage.map((image, index) => (
                <Dot slide={index} key={index}>
                  <Image
                    className="d-block w-100 product-card-image image-choice"
                    src={image}
                  />
                </Dot>
              ))}
            </div>
          </CarouselProvider>
        )}
      </div>
      {/* --------------------- description -------------------------------------- */}

      <div className="desc-wrapper">
        {/* <div className="description-option">
          <p className="description-option-text">DESCRIPTION</p><p className="description-option-semicolon">:</p>
          <Link
            className="description-option-btn"
            style={descriptionLanguage === "EN" ? { color: "#0991099d" } : {}}
            onClick={() => {
              setDescriptionLanguage("EN");
            }}
          >
            ENG
          </Link>
          <Link
            className="description-option-btn"
            style={descriptionLanguage === "CN" ? { color: "#0991099d" } : {}}
            onClick={() => {
              setDescriptionLanguage("CN");
            }}
          >
            中文
          </Link>
        </div> */}
        <div className="ecommerce-option">
          <p className="description-option-text">E-COMMERCE</p><p className="description-option-semicolon">:</p>
          <Link className="shopee-wrapper" to={shopeeLink} target="_blank">
            <img src={shopeeLogo} alt='shopee-logo'></img>
          </Link>
          <Link className="shopee-wrapper" to={lazadaLink} target="_blank">
            <img src={lazadaLogo} alt='lazada-logo'></img>
          </Link>
        </div>

        <div className="description-content">
          {descriptionContent &&
            descriptionContent.map((desc) => {
              if (desc === " ") {
                return <div className="empty-line"></div>;
              } else {
                return <h6 className="text-line">{desc}</h6>;
              }
            })}
          {descriptionLanguage === "EN" && (
            <div>
              <p className="wholesale-text">
                To find out more about our wholesale price,{" "}
              </p>
              <Link
                className="whatsapp-wrapper"
                to="https://wa.link/jmci91"
                target="_blank"
              >
                contact us.<img src={whatsappLogo} alt='whatsapp-logo'></img>
              </Link>
            </div>
          )}
          {descriptionLanguage === "CN" && (
            <div>
              <p className="wholesale-text">若想要了解更多关于我们的批发价, </p>
              <Link
                className="whatsapp-wrapper"
                to="https://wa.link/i0t73p"
                target="_blank"
              >
                请联系我们。<img src={whatsappLogo} alt='whatsapp-logo'></img>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
