import React from "react";
import "./style/index.scss";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";

import HeaderCN from "./components/HeaderCN";
import FooterCN from "./components/FooterCN";
import AboutUsCN from "./components/AboutUsCN";
import ContactUsCN from "./components/ContactUsCN";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <div className="page-body">
          {/* <Suspense fallback={<div></div>}> */}
          {/* Switch replaced with Routes in newer react-router-dom */}
          <Routes>
            {/* component replaced with element and {Home} replaced with {<Home/>} in newer react-router-dom */}
            <Route path="/" element={<Navigate to="/en" />} />
            <Route path="/aboutus" element={<Navigate to="/en/aboutus" />} />
            <Route path="/contactus" element={<Navigate to="/en/contactus" />} />
            <Route exact path="/en" element={<><Header /><Home /><Footer /></>} />
            <Route exact path="/en/aboutus" element={<><Header /><AboutUs /><Footer /></>} />
            <Route exact path="/en/contactus" element={<><Header /><ContactUs /><Footer /></>} />
            <Route exact path="/cn" element={<><HeaderCN /><Home /><FooterCN /></>} />
            <Route exact path="/cn/aboutus" element={<><HeaderCN /><AboutUsCN /><FooterCN /></>} />
            <Route exact path="/cn/contactus" element={<><HeaderCN /><ContactUsCN /><FooterCN /></>} />
          </Routes>
          {/* </Suspense> */}
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
