const biofiberDescriptionEN = [
  "INFINITI BIOFIBER - Comprehensive Fiber Enzymes Detox for Weight Loss & Digestive Health (30 x 1,000mg Chewable Tablets)", 
  " ",
  "✅ What is INFINITI BIOFIBER:", 
  "✔ INFINITI BIOFIBER is a holistic mixture of detoxifying fibers, enzymes, and nutrients aimed at bolstering digestive health, easing constipation, facilitating weight loss, and fostering overall well-being.", 
  " ", 
  "✅ Main Benefits:", 
  "✔ Supports body detoxification.", 
  "✔ Cleanses the intestines and relieves constipation.", 
  "✔ Supports weight loss and reduces belly size.", 
  "✔ Supports gut and digestive health.", 
  "✔ Helps eliminate excess water.", 
  "✔ Promotes nutrient absorption.", 
  " ", 
  "✅ Ingredients and Specifications:", 
  "🔎 Kiwi Powder:", 
  "✔ Aids in weight loss.", 
  "✔ Rich in dietary fiber, low in calories.", 
  "✔ Promotes digestion and absorption.", 
  "✔ Produces a sense of satiety.", 
  " ", 
  "🔎 Apple Powder:", 
  "✔ Improves heart health.", 
  "✔ Antibacterial and anti-viral properties.", 
  "✔ Boosts eye and bone health.", 
  "✔ Relieves constipation.", 
  " ", 
  "🔎 Soluble Fiber - Fibersol®-2:", 
  "✔ Increases fiber content.", 
  "✔ Improves digestion and prevents constipation.", 
  "✔ Regulates blood sugar and cholesterol levels.", 
  " ", 
  "🔎 Oat Fiber - Vitacel:", 
  "✔ Supplies and supplements nutrients, fiber, and protein.", 
  "✔ Supports gut and digestive health.", 
  "✔ Enhances the immune system.", 
  " ", 
  "🔎 Lotus Leaf Extract:", 
  "✔ Promotes large intestine peristalsis.", 
  "✔ Helps defecate.", 
  "✔ Removes toxins.", 
  "✔ Prevents fat accumulation.", 
  " ", 
  "🔎 Digezyme®:", 
  "✔ Breaks down proteins, cellulose, starches, and other foodstuffs.", 
  "✔ Eases nutrient absorption.", 
  " ", 
  "🔎 Wheat Grass Powder:", 
  "✔ Boosts metabolism.", 
  "✔ Detoxifies the body of heavy metals.", 
  "✔ Purifies the liver.", 
  "✔ Improves digestion.", 
  " ", 
  "🔎 Vitamin C:", 
  "✔ Combats free radicals.", 
  "✔ Neutralizes the effects of nitrites.", 
  "✔ Repairs and regenerates tissues.", 
  "✔ Protects skin health.", 
  "✔ Enhances the immune system.", 
  " ", 
  "🔎 Vitamin E:", 
  "✔ High antioxidant properties.", 
  "✔ Strong anti-inflammatory properties.", 
  "✔ Inhibits platelet aggregation.", 
  "✔ Enhances the immune system.", 
  " ", 
  "🔎 Probiotic (Bifidobacterium Longum) - Morinaga BB536:", 
  "✔ Improves the balance of microbes in the intestine.", 
  "✔ Adjusts the composition of intestinal bacteria.", 
  "✔ Inhibits harmful bacteria.", 
  "✔ Enhances the epidemic prevention ability of the digestive tract.", 
  " ", 
  "✅ Suitable Ages:", 
  "✔ Under 10 years: 1 chewable tablet daily.", 
  "✔ 10 years and above: Up to 3 chewable tablets daily.", 
  " ", 
  "✅ Direction of Use:", 
  "✔ Direct consume 1 chewable tablet.", 
  " ", 
  "✅ Recommended Dosage:", 
  "✔ General Well-being: 1 tablet daily", 
  "✔ Moderate Concern: 2 tablets daily", 
  "✔ Serious Condition: 3 tablets daily", 
  " ", 
  "✅ Quantity per Bottle:", 
  "✔ 30 Chewable Tablets", 
  " ", 
  "✅ Certification:", 
  "✔ HACCP, ISO 22000, ISO 9001, GMP, MESTI, (HALAL Application Pending Approval)", 
  " ",
  "✅ Best Price: ",
  "✔️ 1 Bottle: RM56.88 ",
  " ",
  "🚛 Free shipping within Malaysia. ",
  "✉️ Contact us to place your order now.",
];


const biofiberDescriptionCN = [
  ""
];

const bioblockDescriptionEN = [
  "INFINITI BIOBLOCK - Comprehensive Carbohydrate & Fat Blocker with Metabolism Boost (30 x 1,000mg Chewable Tablets)", 
  " ", 
  "✅ What is INFINITI BIOBLOCK:", 
  "✔ INFINITI BIOBLOCK is a comprehensive formula that combines powerful ingredients to block carbohydrate and fat absorption, increase fat burning, suppress appetite, and boost metabolism, supporting weight management and overall wellness.", 
  " ", 
  "✅ Main Benefits:", 
  "✔ Blocks carbohydrate and starch decomposition.", 
  "✔ Reduces fat and glucose absorption.", 
  "✔ Inhibits fat accumulation.", 
  "✔ Increases fat burning.", 
  "✔ Suppresses appetite.", 
  "✔ Boosts metabolism.", 
  " ", 
  "✅ Ingredients and Specifications:", 
  "🔎 Pomegranate Powder:", 
  "✔ High antioxidants enhance metabolism.", 
  "✔ Aids in calorie burning.", 
  "✔ Regulates blood sugar levels by reducing insulin resistance.", 
  " ", 
  "🔎 Lactose:", 
  "✔ Source of energy.", 
  "✔ Increases absorption of certain minerals.", 
  "✔ Relatively low glycemic index, beneficial for people with diabetes.", 
  " ", 
  "🔎 Red Orange Extract - MOROSIL:", 
  "✔ Reduces the size of fat cells.", 
  "✔ Induces lipolysis.", 
  "✔ Inhibits fat accumulation.", 
  "✔ Antioxidant properties.", 
  " ", 
  "🔎 Mixed Fruit Powder:", 
  "✔ Reduces damage to cells.", 
  "✔ Boosts the immune system.", 
  " ", 
  "🔎 Chitosan:", 
  "✔ Strong surface adsorption capacity.", 
  "✔ Absorbs various toxins in the body.", 
  " ", 
  "🔎 Raspberry Ketone:", 
  "✔ Promotes breakdown of lipids in fat cells.", 
  "✔ Prevents increase of fat in the liver and visceral fat in surrounding organs.", 
  " ", 
  "🔎 Green Tea Extract:", 
  "✔ Increases fat burning.", 
  "✔ Increases metabolic rate.", 
  "✔ Reduces infection.", 
  "✔ Prevents cell damage.", 
  "✔ Improves liver function.", 
  "✔ Diuretic effects.", 
  " ", 
  "🔎 Green Coffee Extract:", 
  "✔ Promotes fat-burning and weight loss.", 
  "✔ Improves glucose and lipid metabolism.", 
  "✔ Antioxidant properties.", 
  "✔ Anti-inflammatory effects.", 
  " ", 
  "🔎 White Kidney Bean Extract:", 
  "✔ Inhibits action of α-amylase.", 
  "✔ Blocks decomposition of starch.", 
  "✔ Reduces fat and glucose absorption.", 
  "✔ Reduces fat synthesis.", 
  " ", 
  "🔎 Garcinia Cambogia Extract:", 
  "✔ Blocks fat.", 
  "✔ Suppresses appetite.", 
  "✔ Inhibits citrate lyase to make fat from carbohydrates.", 
  "✔ Aids in weight loss.", 
  " ", 
  "🔎 Lotus Leaf Extract:", 
  "✔ Promotes large intestine peristalsis.", 
  "✔ Prevents fat accumulation.", 
  " ", 
  "🔎 Vitamin E:", 
  "✔ High antioxidant properties.", 
  "✔ Strong anti-inflammatory properties.", 
  "✔ Inhibits platelet aggregation.", 
  "✔ Enhances the immune system.", 
  " ", 
  "✅ Suitable Ages:", 
  "✔ Under 10 years: 1 chewable tablet daily.", 
  "✔ 10 years and above: Up to 3 chewable tablets daily.", 
  " ", 
  "✅ Direction of Use:", 
  "✔ Direct consume 1 chewable tablet. ", 
  " ", 
  "✅ Recommended Dosage:", 
  "✔ General Well-being: 1 tablet daily", 
  "✔ Moderate Concern: 2 tablets daily", 
  "✔ Serious Condition: 3 tablets daily", 
  " ", 
  "✅ Quantity per Bottle:", 
  "✔ 30 Chewable Tablets", 
  " ", 
  "✅ Certification:", 
  "✔ HACCP, ISO 22000, ISO 9001, GMP, MESTI, (HALAL Application Pending Approval)",
  " ",
  "✅ Best Price: ",
  "✔️ 1 Bottle: RM56.88 ",
  " ",
  "🚛 Free shipping within Malaysia. ",
  "✉️ Contact us to place your order now.",
];

const bioblockDescriptionCN = [
  ""
];
const biobeautyDescriptionEN = [
  "INFINITI BIOBEAUTY - Comprehensive Collagen Boost with Stem Cell Anti-Aging Stimulation (30 x 1,000mg Chewable Tablets)", 
  " ", 
  "✅ What is INFINITI BIOBEAUTY:", 
  "✔ INFINITI BIOBEAUTY is a comprehensive blend of collagen-boosting ingredients designed to enhance skin health and combat aging.", 
  " ", 
  "✅ Main Benefits:", 
  "✔ Collagen boost for wrinkles and healing.", 
  "✔ Skin hydration with UV protection.", 
  "✔ Cell stimulation for improved elasticity.", 
  "✔ Increases vitality and firmness.", 
  "✔ Anti-aging and radiance boost.", 
  "✔ Scar and pore repair.", 
  " ", 
  "✅ Ingredients and Specifications:", 
  "🔎 Orange Powder:", 
  "✔ Enhances beauty.", 
  "✔ Provides high-volume antioxidants and minerals.", 
  "✔ Enhances immunity.", 
  "✔ Promotes intestinal peristalsis.", 
  "✔ Lowers cholesterol, blood lipids, and blood pressure.", 
  " ", 
  "🔎 Fish Collagen Peptide - Collagen Optima:", 
  "✔ Boosts body collagen production.", 
  "✔ Boosts wound healing and regeneration.", 
  "✔ Reduces wrinkles and fine lines.", 
  "✔ Promotes skin hydration.", 
  " ", 
  "🔎 Tremella Fuciformis Extract:", 
  "✔ Skin whitening and anti-wrinkle effects.", 
  "✔ Boosts skin collagen.", 
  "✔ Antioxidant properties.", 
  "✔ Anti-inflammatory effects.", 
  "✔ Cleanses the skin and improves skin water capacity.", 
  " ", 
  "🔎 White Curcumin:", 
  "✔ Powerful antioxidant.", 
  "✔ Keeps skin moisturized and hydrated.", 
  "✔ Reduces excess skin oil.", 
  "✔ Eliminates wrinkles.", 
  "✔ Provides UV protection.", 
  " ", 
  "🔎 Bird Nest Extract:", 
  "✔ Stimulates skin cell proliferation, differentiation, and growth.", 
  "✔ Promotes wound healing.", 
  "✔ Promotes skin-tight connection.", 
  "✔ Improves skin barrier function.", 
  "✔ Improves skin dryness, elasticity, and sensitivity.", 
  "✔ Prevents trans-epidermal moisture loss.", 
  "✔ Improves learning ability.", 
  "✔ Enhances immunity.", 
  " ", 
  "🔎 Rice Peptide:", 
  "✔ Reduces UV damage, lines, wrinkles, hyperpigmentation, and dead skin cells.", 
  "✔ Increases skin ceramide production.", 
  "✔ Increases collagen synthesis.", 
  "✔ Lightens skin.", 
  " ", 
  "🔎 Apple Stem Cell - PhytoCellTec™ Malus Domestica:", 
  "✔ Helps skin stem cells maintain their characteristics and capacity to build new tissues.", 
  "✔ Delays aging.", 
  "✔ Anti-wrinkle effects.", 
  "✔ Protects the longevity of skin stem cells.", 
  "✔ Delays senescence of essential cells.", 
  "✔ Increases vitality of skin stem cells.", 
  "✔ Combats chronological aging.", 
  " ", 
  "🔎 Vitamin E:", 
  "✔ High antioxidant properties.", 
  "✔ Strong anti-inflammatory properties.", 
  "✔ Inhibits platelet aggregation.", 
  "✔ Enhances the immune system.", 
  " ", 
  "✅ Suitable Ages:", 
  "✔ Under 10 years: 1 chewable tablet daily.", 
  "✔ 10 years and above: Up to 3 chewable tablets daily.", 
  " ", 
  "✅ Direction of Use:", 
  "✔ Direct consume 1 chewable tablet. ", 
  " ", 
  "✅ Recommended Dosage:", 
  "✔ General Well-being: 1 tablet daily", 
  "✔ Moderate Concern: 2 tablets daily", 
  "✔ Serious Condition: 3 tablets daily", 
  " ", 
  "✅ Quantity per Bottle:", 
  "✔ 30 Chewable Tablets", 
  " ", 
  "✅ Certification:", 
  "✔ HACCP, ISO 22000, ISO 9001, GMP, MESTI, (HALAL Application Pending Approval)",
  " ",
  "✅ Best Price: ",
  "✔️ 1 Bottle: RM56.88 ",
  " ",
  "🚛 Free shipping within Malaysia. ",
  "✉️ Contact us to place your order now.",
  
];

const biobeautyDescriptionCN = [
  ""
];

const biowhiteDescriptionEN = [
  "INFINITI BIOWHITE - Comprehensive Whitening & Brightening with Dark Spot Reduction (30 x 1,000mg Chewable Tablets)", 
  " ", 
  "✅ What is INFINITI BIOWHITE:", 
  "✔ INFINITI BIOWHITE is a specialized formula designed to brighten and whiten the skin while reducing dark spots, unveiling a radiant complexion.", 
  " ", 
  "✅ Main Benefits:", 
  "✔ Enhances skin whitening.", 
  "✔ Enhances skin brightness.", 
  "✔ Protects against UV-induced damage.", 
  "✔ Helps moisturize and hydrate the skin.", 
  "✔ Reduces skin inflammation.", 
  "✔ Reduces dark spots.", 
  " ", 
  "✅ Ingredients and Specifications:", 
  "🔎 Lemon Powder:", 
  "✔ High antioxidant properties.", 
  "✔ Promotes fluid and quenches thirst.", 
  "✔ Antibacterial effects.", 
  "✔ Helps brighten the skin and reduce dark spots and hyperpigmentation.", 
  " ", 
  "🔎 Yeast Extract - Cerepron-F ®:", 
  "✔ Inhibits melanin biosynthesis.", 
  "✔ Enhances skin brightness.", 
  "✔ Exhibits anti-inflammatory and anti-wrinkle effects.", 
  " ", 
  "🔎 Broccoli Sprout Extract:", 
  "✔ Antioxidant protection.", 
  "✔ Anti-glycation effects.", 
  "✔ Anti-carbonylation effects.", 
  "✔ Anti-melanogenesis effects.", 
  "✔ UV protection.", 
  "✔ Brightening and even skin tone.", 
  " ", 
  "🔎 Beta Carotene:", 
  "✔ Efficient in photoprotection.", 
  "✔ Scavenges singlet oxygen and peroxyl radicals.", 
  "✔ Protects against UV-induced skin damage.", 
  "✔ Eliminates reactive oxygen species.", 
  "✔ Absorbs UV light.", 
  " ", 
  "🔎 Sakura Extract:", 
  "✔ Rich in antioxidants and anti-inflammatory properties.", 
  "✔ Anti-aging effects.", 
  "✔ Helps moisturize, hydrate, and whiten the skin.", 
  " ", 
  "🔎 Pomegranate Extract:", 
  "✔ High antioxidant properties.", 
  "✔ Improves skin elasticity.", 
  "✔ Delays aging.", 
  "✔ Fights skin inflammation.", 
  "✔ Whitening effects.", 
  " ", 
  "🔎 Acerola Cherry Extract:", 
  "✔ Brightens skin.", 
  "✔ Protects against UV rays.", 
  "✔ Boosts collagen production.", 
  "✔ Moisturizes skin.", 
  "✔ Anti-aging effects.", 
  "✔ Promotes weight loss.", 
  "✔ Controls diabetes.", 
  "✔ Prevents cancer.", 
  "✔ Boosts immunity.", 
  "✔ Promotes heart and eye health.", 
  " ", 
  "🔎 O2 Premix:", 
  "✔ Rich in antioxidants.", 
  "✔ Protects skin from UV-induced damage.", 
  "✔ Helps brighten the skin.", 
  "✔ Reduces skin inflammation.", 
  " ", 
  "🔎 Vitamin C:", 
  "✔ Combats free radicals.", 
  "✔ Neutralizes the effects of nitrites.", 
  "✔ Repairs and regenerates tissues.", 
  "✔ Protects skin health.", 
  "✔ Enhances immunity.", 
  " ", 
  "🔎 Vitamin E:", 
  "✔ High antioxidant properties.", 
  "✔ Strong anti-inflammatory properties.", 
  "✔ Inhibits platelet aggregation.", 
  "✔ Enhances the immune system.", 
  " ", 
  "✅ Suitable Ages:", 
  "✔ Under 10 years: 1 chewable tablet daily.", 
  "✔ 10 years and above: Up to 3 chewable tablets daily.", 
  " ", 
  "✅ Direction of Use:", 
  "✔ Direct consume 1 chewable tablet. ", 
  " ", 
  "✅ Recommended Dosage:", 
  "✔ General Well-being: 1 tablet daily", 
  "✔ Moderate Concern: 2 tablets daily", 
  "✔ Serious Condition: 3 tablets daily", 
  " ", 
  "✅ Quantity per Bottle:", 
  "✔ 30 Chewable Tablets", 
  " ", 
  "✅ Certification:", 
  "✔ HACCP, ISO 22000, ISO 9001, GMP, MESTI, (HALAL Application Pending Approval)",
  " ",
  "✅ Best Price: ",
  "✔️ 1 Bottle: RM56.88 ",
  " ",
  "🚛 Free shipping within Malaysia. ",
  "✉️ Contact us to place your order now.",
];

const biowhiteDescriptionCN = [
  ""
];

const biogentDescriptionEN = [
  "INFINITI BIOGENT - Comprehensive Men's Health for Masculine Health & Overall Vitality (30 x 1,000mg Chewable Tablets)", 
  " ", 
  "✅ What is INFINITI BIOGENT:", 
  "✔ INFINITI BIOGENT is a men's health supplement that primarily boosts stamina and energy levels, while also supporting fertility, vitality, hormonal balance, prostate, and urinary health.", 
  " ", 
  "✅ Main Benefits:", 
  "✔ Improves stamina and energy levels.", 
  "✔ Promotes fertility and enhances sperm quality.", 
  "✔ Enhances intimate wellness and masculine function.", 
  "✔ Supports prostate and urinary health.", 
  "✔ Improves testosterone levels in men.", 
  "✔ Supports hormonal balance.", 
  " ", 
  "✅ Ingredients and Specifications:", 
  "🔎 Blackcurrant Powder:", 
  "✔ Rich in antioxidants that may reduce the risk of chronic disease.", 
  "✔ Strengthens the immune system.", 
  "✔ Supports prostate health.", 
  " ", 
  "🔎 Maca Powder:", 
  "✔ Promotes fertility and sperm quality.", 
  "✔ Enhances intimate wellness and masculine function.", 
  "✔ Supports hormonal balance and prostate health.", 
  "✔ Reduces fatigue and enhances physical performance.", 
  " ", 
  "🔎 Tongkat Ali Powder:", 
  "✔ Promotes secretion of testosterone.", 
  "✔ Improves sperm quality.", 
  "✔ Increases sperm count, size, and movement speed.", 
  " ", 
  "🔎 Synura:", 
  "✔ High antioxidant properties.", 
  "✔ Helps maintain male testosterone levels.", 
  "✔ Supports prostate and urinary health.", 
  "✔ Improves overall well-being for men.", 
  " ", 
  "🔎 Tribulus Terrestris Extract:", 
  "✔ Improves secretion of luteinizing hormones.", 
  "✔ Enhances men's desire and endurance.", 
  "✔ Increases sperm count and activity.", 
  " ", 
  "🔎 Korean Ginseng Root Powder:", 
  "✔ Strengthens the immune system.", 
  "✔ Enhances brain function.", 
  "✔ Fights fatigue.", 
  "✔ Improves symptoms of erectile dysfunction.", 
  " ", 
  "🔎 Cordyceps Powder:", 
  "✔ Improves testosterone levels in men.", 
  "✔ Enhances stamina and energy levels.", 
  "✔ Improves intimate wellness and fertility health.", 
  " ", 
  "🔎 L-Arginine:", 
  "✔ Regulates hormonal balance.", 
  "✔ Improves sperm quality.", 
  "✔ Lowers blood pressure.", 
  "✔ Reduces symptoms of angina and peripheral arterial disease.", 
  "✔ Treats erectile dysfunction due to physical causes.", 
  " ", 
  "🔎 Lycopene Extract:", 
  "✔ Plays an essential role in prostate health.", 
  "✔ Effectively inhibits the growth of normal human prostate epithelial cells.", 
  " ", 
  "🔎 Vitamin E:", 
  "✔ High antioxidant properties.", 
  "✔ Strong anti-inflammatory properties.", 
  "✔ Inhibits platelet aggregation.", 
  "✔ Enhances the immune system.", 
  " ", 
  "✅ Suitable Ages:", 
  "✔ Under 10 years: 1 chewable tablet daily.", 
  "✔ 10 years and above: Up to 3 chewable tablets daily.", 
  " ", 
  "✅ Direction of Use:", 
  "✔ Direct consume 1 chewable tablet. ", 
  " ", 
  "✅ Recommended Dosage:", 
  "✔ General Well-being: 1 tablet daily", 
  "✔ Moderate Concern: 2 tablets daily", 
  "✔ Serious Condition: 3 tablets daily", 
  " ", 
  "✅ Quantity per Bottle:", 
  "✔ 30 Chewable Tablets", 
  " ", 
  "✅ Certification:", 
  "✔ HACCP, ISO 22000, ISO 9001, GMP, MESTI, (HALAL Application Pending Approval)",
  " ",
  "✅ Best Price: ",
  "✔️ 1 Bottle: RM56.88 ",
  " ",
  "🚛 Free shipping within Malaysia. ",
  "✉️ Contact us to place your order now.",
];

const biogentDescriptionCN = [
  ""
];

const bioladyDescriptionEN = [
  "INFINITI BIOLADY - Comprehensive Women's Health for Feminine Health & Overall Vitality (30 x 1,000mg Chewable Tablets)", 
  " ", 
  "✅ What is INFINITI BIOLADY:", 
  "✔ INFINITI BIOLADY is a women's health supplement that promotes hormonal balance, supports menstrual and urinary health, enhances breast firmness and enlargement, maintains vaginal health and elasticity, and alleviates menopausal symptoms.", 
  " ", 
  "✅ Main Benefits:", 
  "✔ Promotes hormonal balance.", 
  "✔ Supports menstrual and urinary health.", 
  "✔ Supports breast firmness and enlargement.", 
  "✔ Enhances intimate wellness and vitality.", 
  "✔ Maintains vaginal health and elasticity.", 
  "✔ Alleviates menopausal symptoms.", 
  " ", 
  "✅ Ingredients and Specifications:", 
  "🔎 Strawberry Powder:", 
  "✔ Supports gastrointestinal health.", 
  "✔ Rich in nutrients for bone and brain health.", 
  "✔ Aids in managing blood pressure.", 
  " ", 
  "🔎 Blackcurrant Powder:", 
  "✔ Rich in antioxidants for overall health.", 
  "✔ Supports immune system function.", 
  "✔ Promotes menstrual health.", 
  " ", 
  "🔎 Cranberry Powder:", 
  "✔ Supports urinary tract health.", 
  "✔ Provides anti-inflammatory benefits.", 
  "✔ Supports skin, heart, and digestive health.", 
  " ", 
  "🔎 Kacip Fatimah Extract:", 
  "✔ Supports hormonal balance.", 
  "✔ Promotes intimate wellness and vitality.", 
  " ", 
  "🔎 Licorice Extract:", 
  "✔ Supports digestive health.", 
  "✔ Alleviates menstrual discomfort.", 
  "✔ Provides antioxidant benefits.", 
  " ", 
  "🔎 Estro G - EstroG-100®:", 
  "✔ Supports menopausal health.", 
  "✔ Promotes wound healing.", 
  " ", 
  "🔎 Pueraria Mirifica Extract Powder:", 
  "✔ Alleviates menopausal symptoms.", 
  "✔ Supports breast firmness and enlargement.", 
  "✔ Supports intimate wellness.", 
  "✔ Provides anti-aging benefits.", 
  " ", 
  "🔎 Pueraria Lobata Extract:", 
  "✔ Supports hormone secretion.", 
  "✔ Supports skin health.", 
  " ", 
  "🔎 Sea Buckthorn Powder - Omegia®:", 
  "✔ Supports skin health.", 
  "✔ Moisturizes and soothes dry skin.", 
  " ", 
  "🔎 Vitamin C:", 
  "✔ Supports skin health and immunity.", 
  " ", 
  "🔎 Vitamin E:", 
  "✔ Provides antioxidant benefits.", 
  "✔ Supports immunity.", 
  " ", 
  "✅ Suitable Ages:", 
  "✔ Under 10 years: 1 chewable tablet daily.", 
  "✔ 10 years and above: Up to 3 chewable tablets daily.", 
  " ", 
  "✅ Direction of Use:", 
  "✔ Direct consume 1 chewable tablet. ", 
  " ", 
  "✅ Recommended Dosage:", 
  "✔ General Well-being: 1 tablet daily", 
  "✔ Moderate Concern: 2 tablets daily", 
  "✔ Serious Condition: 3 tablets daily", 
  " ", 
  "✅ Quantity per Bottle:", 
  "✔ 30 Chewable Tablets", 
  " ", 
  "✅ Certification:", 
  "✔ HACCP, ISO 22000, ISO 9001, GMP, MESTI, (HALAL Application Pending Approval)",
  " ",
  "✅ Best Price: ",
  "✔️ 1 Bottle: RM56.88 ",
  " ",
  "🚛 Free shipping within Malaysia. ",
  "✉️ Contact us to place your order now.",
];

const bioladyDescriptionCN = [
  ""
];

const biovisionDescriptionEN = [
  "INFINITI BIOVISION - Comprehensive Eye Health with Enhanced Vision and Retinal Health (30 x 1,000mg Chewable Tablets)", 
  " ", 
  "✅ What is INFINITI BIOVISION:", 
  "✔ INFINITI BIOVISION is a cutting-edge eye health supplement that nourishes and protects your eyes, promoting optimal vision clarity and comprehensive eye wellness.", 
  " ", 
  "✅ Main Benefits:", 
  "✔ Improves macular and retinal health.", 
  "✔ Provides blue light protection.", 
  "✔ Supports tear production.", 
  "✔ Enhances night vision.", 
  "✔ Protects eye tissues.", 
  "✔ Relieves eye fatigue.", 
  " ", 
  "✅ Ingredients and Specifications:", 
  "🔎 Grape Powder:", 
  "✔ Protects the eyes from oxidative stress caused by free radicals.", 
  "✔ Helps protect the macula from damage.", 
  "✔ Reduces the risk of age-related macular degeneration (AMD) and UV damage to the eyes.", 
  " ", 
  "🔎 Mixed Fruit Powder:", 
  "✔ Reduces damage to cells.", 
  "✔ Boosts the immune system.", 
  " ", 
  "🔎 Lutein:", 
  "✔ Helps reduce inflammation in the eyes.", 
  "✔ Fights off free radicals.", 
  "✔ Improves visual contrast sensitivity.", 
  "✔ Protects eyes from blue light.", 
  "✔ Beneficial in preventing diabetic retinopathy and its progression.", 
  " ", 
  "🔎 Zeaxanthin - Innobio®:", 
  "✔ Supports macular and retinal health.", 
  "✔ Improves visual function.", 
  "✔ Provides blue light protection.", 
  "✔ Reduces the risk of cataracts and eye diseases.", 
  " ", 
  "🔎 Beta Carotene:", 
  "✔ Maintains eye health and prevents ophthalmic diseases.", 
  "✔ Reduces oxidative stress.", 
  "✔ Minimizes the risk of cataracts.", 
  "✔ Supports tear production.", 
  " ", 
  "🔎 Astaxanthin Powder - Astabio®:", 
  "✔ Powerful antioxidant.", 
  "✔ Helps eyes recover from strain and fatigue.", 
  "✔ Increases blood flow to the eyes.", 
  "✔ Improves glucose metabolism.", 
  "✔ Reduces blood pressure in patients with type 2 diabetes mellitus.", 
  " ", 
  "🔎 Bilberry Extract:", 
  "✔ Prevents retinal oxidation.", 
  "✔ Removes free radicals that damage the retina.", 
  "✔ Improves night vision.", 
  "✔ Reduces eye fatigue.", 
  " ", 
  "🔎 Blueberry Extract:", 
  "✔ High antioxidants help maintain healthy eye tissues.", 
  "✔ Prevents vision loss.", 
  "✔ Improves visual function and macular health.", 
  " ", 
  "🔎 Vitamin C:", 
  "✔ Combats free radicals and repairs tissues.", 
  "✔ Protects skin health and enhances immunity.", 
  " ", 
  "🔎 Vitamin E:", 
  "✔ Acts as an antioxidant and anti-inflammatory.", 
  "✔ Inhibits platelet aggregation and enhances the immune system.", 
  " ", 
  "✅ Suitable Ages:", 
  "✔ Under 10 years: 1 chewable tablet daily.", 
  "✔ 10 years and above: Up to 3 chewable tablets daily.", 
  " ", 
  "✅ Direction of Use:", 
  "✔ Direct consume 1 chewable tablet. ", 
  " ", 
  "✅ Recommended Dosage:", 
  "✔ General Well-being: 1 tablet daily", 
  "✔ Moderate Concern: 2 tablets daily", 
  "✔ Serious Condition: 3 tablets daily", 
  " ", 
  "✅ Quantity per Bottle:", 
  "✔ 30 Chewable Tablets", 
  " ", 
  "✅ Certification:", 
  "✔ HACCP, ISO 22000, ISO 9001, GMP, MESTI, (HALAL Application Pending Approval)",
  " ",
  "✅ Best Price: ",
  "✔️ 1 Bottle: RM56.88 ",
  " ",
  "🚛 Free shipping within Malaysia. ",
  "✉️ Contact us to place your order now.",
  
];

const biovisionDescriptionCN = [
  ""
];

const biobrainDescriptionEN = [
  "INFINITI BIOBRAIN - Comprehensive Brain Health with Memory and Cognitive Boost (30 x 1,000mg Chewable Tablets)", 
  " ", 
  "✅ What is INFINITI BIOBRAIN:", 
  "✔ INFINITI BIOBRAIN is a comprehensive brain health supplement that enhances memory, cognition, and mental development. It supports learning, memory retention, guards against cognitive decline and neurological disorders, and provides antioxidant brain support.", 
  " ", 
  "✅ Main Benefits:", 
  "✔ Enhances mental development.", 
  "✔ Improves memory and cognitive functions.", 
  "✔ Supports learning ability and memory retention.", 
  "✔ Protects against age-related cognitive decline.", 
  "✔ Guards against neurological disorders.", 
  "✔ Provides antioxidant support for brain health.", 
  " ", 
  "✅ Ingredients and Specifications:", 
  "🔎 Blueberry Powder:", 
  "✔ Improves memory and cognitive functions.", 
  "✔ Reduces age-related cognitive decline.", 
  "✔ Enhances signaling between brain cells.", 
  " ", 
  "🔎 Docosahexaenoic acid (DHA) Powder:", 
  "✔ Promotes better mental development.", 
  "✔ Lowers the risk of vision loss due to aging.", 
  "✔ Protects brain health.", 
  "✔ Improves cognitive performance and learning ability.", 
  " ", 
  "🔎 Walnut Peptide:", 
  "✔ Acts as an antioxidant to protect brain cells.", 
  "✔ Enhances brain function and learning ability.", 
  "✔ Boosts memory and cognitive performance.", 
  "✔ Reduces fatigue and supports mental alertness.", 
  " ", 
  "🔎 Flaxseed Oil Powder - Innobio®:", 
  "✔ Rich in omega-3 fatty acids.", 
  "✔ Reduces inflammation.", 
  "✔ Improves heart health.", 
  "✔ Protects the brain against aging.", 
  " ", 
  "🔎 Soy Oligopeptide:", 
  "✔ Supplements the body's nitrogen source.", 
  "✔ Restores body strength.", 
  "✔ Relieves fatigue.", 
  "✔ Improves mood.", 
  "✔ Lowers blood pressure, lipids, and blood sugar.", 
  "✔ Reduces blood viscosity.", 
  "✔ Promotes fat and energy metabolism.", 
  " ", 
  "🔎 Coenzyme Q10:", 
  "✔ Antioxidant properties.", 
  "✔ Prevents and decreases the risk of heart disease.", 
  "✔ Reduces inflammation.", 
  "✔ Improves memory and brain performance.", 
  " ", 
  "🔎 Green Tea Extract:", 
  "✔ Boosts cognitive function.", 
  "✔ Supports attention, focus, and mood improvement.", 
  "✔ Enhances memory.", 
  " ", 
  "🔎 Vitamins Minerals Premix:", 
  "✔ Replenishes vitamins and minerals needed by the body.", 
  " ", 
  "🔎 Vitamin C:", 
  "✔ Combats free radicals and repairs tissues.", 
  "✔ Protects skin health and enhances immunity.", 
  " ", 
  "🔎 Vitamin E:", 
  "✔ Acts as an antioxidant and anti-inflammatory.", 
  "✔ Inhibits platelet aggregation and enhances the immune system.", 
  " ", 
  "✅ Suitable Ages:", 
  "✔ Under 10 years: 1 chewable tablet daily.", 
  "✔ 10 years and above: Up to 3 chewable tablets daily.", 
  " ", 
  "✅ Direction of Use:", 
  "✔ Direct consume 1 chewable tablet. ", 
  " ", 
  "✅ Recommended Dosage:", 
  "✔ General Well-being: 1 tablet daily", 
  "✔ Moderate Concern: 2 tablets daily", 
  "✔ Serious Condition: 3 tablets daily", 
  " ", 
  "✅ Quantity per Bottle:", 
  "✔ 30 Chewable Tablets", 
  " ", 
  "✅ Certification:", 
  "✔ HACCP, ISO 22000, ISO 9001, GMP, MESTI, (HALAL Application Pending Approval)",
  " ",
  "✅ Best Price: ",
  "✔️ 1 Bottle: RM56.88 ",
  " ",
  "🚛 Free shipping within Malaysia. ",
  "✉️ Contact us to place your order now.",
];

const biobrainDescriptionCN = [
  ""
];

const bioliverDescriptionEN = [
  "INFINITI BIOLIVER - Comprehensive Liver Health for Detoxification and Disease Prevention (30 x 1,000mg Chewable Tablets)", 
  " ", 
  "✅ What is INFINITI BIOLIVER:", 
  "✔ INFINITI BIOLIVER is specially crafted to promote liver detoxification, reduce the risk of liver diseases, and enhance overall liver health and function.", 
  " ", 
  "✅ Main Benefits:", 
  "✔ Improves liver enzyme levels.", 
  "✔ Lowers the risk of fatty liver disease.", 
  "✔ Supports the regeneration of liver cells.", 
  "✔ Stimulates bile flow and filters harmful chemicals.", 
  "✔ Promotes liver metabolic and detoxification functions.", 
  "✔ Reduces liver fat accumulation, supporting overall liver health.", 
  " ", 
  "✅ Ingredients and Specifications:", 
  "🔎 Apple Powder:", 
  "✔ Rich in antioxidants.", 
  "✔ Promotes detoxification.", 
  "✔ Lowers the risk of fatty liver disease.", 
  "✔ Reduces inflammation.", 
  "✔ Improves liver enzyme levels.", 
  " ", 
  "🔎 Milk Thistle Extract:", 
  "✔ Helps block, detoxify, and eliminate toxins and waste substances.", 
  "✔ Promotes the regeneration of liver cells damaged by injury or disease.", 
  "✔ Reduces liver fat accumulation.", 
  " ", 
  "🔎 Corn Peptide:", 
  "✔ High antioxidants contribute to overall liver health and function.", 
  "✔ Supports the metabolic and detoxification functions of the liver.", 
  " ", 
  "🔎 Dandelion Extract:", 
  "✔ Stimulates the liver to increase bile flow.", 
  "✔ Filters potentially harmful chemicals.", 
  "✔ Helps break down fat during digestion.", 
  "✔ Restores liver function and accelerates the healing process after liver damage.", 
  " ", 
  "🔎 Lotus Leaf Extract:", 
  "✔ Eliminates liver disease by lowering total cholesterol and triglyceride levels.", 
  "✔ Reduces the risk of NAFLD and supports liver health.", 
  "✔ Regulates blood sugar levels and improves insulin sensitivity.", 
  " ", 
  "🔎 Guarana Extract:", 
  "✔ Increases metabolism.", 
  "✔ Boosts energy.", 
  "✔ Reduces fatigue.", 
  " ", 
  "🔎 Green Coffee Extract:", 
  "✔ Shown to have anticancer, antilipidemic, antihypertensive, and anti-diabetic properties.", 
  "✔ Decreases the risk of chronic diseases including liver disease.", 
  "✔ Anti-inflammatory.", 
  " ", 
  "🔎 Vitamins Minerals Premix:", 
  "✔ Replenishes vitamins and minerals needed by the body.", 
  " ", 
  "🔎 Vitamin C:", 
  "✔ Combats free radicals and repairs tissues.", 
  "✔ Protects skin health and enhances immunity.", 
  " ", 
  "🔎 Vitamin E:", 
  "✔ Acts as an antioxidant and anti-inflammatory.", 
  "✔ Inhibits platelet aggregation and enhances the immune system.", 
  " ", 
  "✅ Suitable Ages:", 
  "✔ Under 10 years: 1 chewable tablet daily.", 
  "✔ 10 years and above: Up to 3 chewable tablets daily.", 
  " ", 
  "✅ Direction of Use:", 
  "✔ Direct consume 1 chewable tablet. ", 
  " ", 
  "✅ Recommended Dosage:", 
  "✔ General Well-being: 1 tablet daily", 
  "✔ Moderate Concern: 2 tablets daily", 
  "✔ Serious Condition: 3 tablets daily", 
  " ", 
  "✅ Quantity per Bottle:", 
  "✔ 30 Chewable Tablets", 
  " ", 
  "✅ Certification:", 
  "✔ HACCP, ISO 22000, ISO 9001, GMP, MESTI, (HALAL Application Pending Approval)",
  " ",
  "✅ Best Price: ",
  "✔️ 1 Bottle: RM56.88 ",
  " ",
  "🚛 Free shipping within Malaysia. ",
  "✉️ Contact us to place your order now.",
];

const bioliverDescriptionCN = [
  ""
];

const biorelaxDescriptionEN = [
  "INFINITI BIORELAX - Comprehensive Stress Relief and Deep Sleep Support for Insomnia (30 x 1,000mg Chewable Tablets)", 
  " ", 
  "✅ What is INFINITI BIORELAX:", 
  "✔ INFINITI BIORELAX is a specialized relaxation supplement designed to address insomnia, promoting restful sleep and alleviating anxiety. It enhances sleep quality, induces relaxation, and reduces stress by regulating sleep cycles, calming the mind, and balancing neurotransmitter levels in the brain.", 
  " ", 
  "✅ Main Benefits:", 
  "✔ Helps relax muscles and reduce stress.", 
  "✔ Eases insomnia and enhances sleep quality.", 
  "✔ Relieves anxiety symptoms for deeper sleep.", 
  "✔ Regulates sleep cycles for sustained rest.", 
  "✔ Extends sleep duration and promotes deep stages.", 
  "✔ Balances neurotransmitter GABA for stress and mood regulation.", 
  " ", 
  "✅ Ingredients and Specifications:", 
  "🔎 Mango Powder:", 
  "✔ Magnesium helps relax muscles and stress.", 
  "✔ Vitamin B6 supports the production of neurotransmitters enhancing sleep quality.", 
  " ", 
  "🔎 Passion Flower Extract:", 
  "✔ Helps relieve anxiety symptoms.", 
  "✔ Aids in relaxation and increases slow-wave deep sleep.", 
  "✔ Reduces rapid eye movement sleep, facilitating faster sleep onset.", 
  " ", 
  "🔎 Walnut Peptide:", 
  "✔ Aids in regulating sleep cycles.", 
  "✔ Enhances brain function, learning ability, and memory.", 
  "✔ Acts as an anti-fatigue and antioxidant agent.", 
  " ", 
  "🔎 Jujuba Extract:", 
  "✔ Calms the mind and induces sustained sleep.", 
  "✔ Extends sleep duration and increases time in deep sleep stages.", 
  " ", 
  "🔎 Holy Basil Extract - OciBest®:", 
  "✔ Supports stress adaptation and memory function positively.", 
  "✔ Reduces stress, insomnia, and enhances sleep efficiency.", 
  " ", 
  "🔎 L-Glutamine:", 
  "✔ Builds and balances neurotransmitter GABA in the brain.", 
  "✔ Regulates stress responses and balances mood.", 
  " ", 
  "🔎 Cassia Seed Extract:", 
  "✔ Eases anxiety and promotes healthy sleep.", 
  "✔ Supports vision and cardiovascular health.", 
  " ", 
  "🔎 Flaxseed Oil Powder - Innobio®:", 
  "✔ Rich in tryptophan and omega-3 fatty acids.", 
  "✔ Increases serotonin levels for improved sleep quality.", 
  " ", 
  "🔎 Vitamin C:", 
  "✔ Combats free radicals and repairs tissues.", 
  "✔ Protects skin health and enhances immunity.", 
  " ", 
  "🔎 Vitamin E:", 
  "✔ Acts as an antioxidant and anti-inflammatory.", 
  "✔ Inhibits platelet aggregation and enhances the immune system.", 
  " ", 
  "✅ Suitable Ages:", 
  "✔ Under 10 years: 1 chewable tablet daily.", 
  "✔ 10 years and above: Up to 3 chewable tablets daily.", 
  " ", 
  "✅ Direction of Use:", 
  "✔ Direct consume 1 chewable tablet. ", 
  " ", 
  "✅ Recommended Dosage:", 
  "✔ General Well-being: 1 tablet daily", 
  "✔ Moderate Concern: 2 tablets daily", 
  "✔ Serious Condition: 3 tablets daily", 
  " ", 
  "✅ Quantity per Bottle:", 
  "✔ 30 Chewable Tablets", 
  " ", 
  "✅ Certification:", 
  "✔ HACCP, ISO 22000, ISO 9001, GMP, MESTI, (HALAL Application Pending Approval)",
  " ",
  "✅ Best Price: ",
  "✔️ 1 Bottle: RM56.88 ",
  " ",
  "🚛 Free shipping within Malaysia. ",
  "✉️ Contact us to place your order now.",
];

const biorelaxDescriptionCN = [
  ""
];

export const data = {
  biofiber: { CN: biofiberDescriptionCN, EN: biofiberDescriptionEN },
  bioblock: { CN: bioblockDescriptionCN, EN: bioblockDescriptionEN },
  biobeauty: { CN: biobeautyDescriptionCN, EN: biobeautyDescriptionEN },
  biowhite: { CN: biowhiteDescriptionCN, EN: biowhiteDescriptionEN },
  biogent: { CN: biogentDescriptionCN, EN: biogentDescriptionEN },
  biolady: { CN: bioladyDescriptionCN, EN: bioladyDescriptionEN },
  biovision: { CN: biovisionDescriptionCN, EN: biovisionDescriptionEN },
  biobrain: { CN: biobrainDescriptionCN, EN: biobrainDescriptionEN },
  bioliver: { CN: bioliverDescriptionCN, EN: bioliverDescriptionEN },
  biorelax: { CN: biorelaxDescriptionCN, EN: biorelaxDescriptionEN },

};
